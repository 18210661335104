import http from '../../services/helpers/http';
import enums from '../../services/helpers/enums';
import i18n from '../../services/lang';

const validators = {
	// if LocationDistance is set, then Location can by City type only
	locationOnlyCity(value, field, model) {
		return model.LocationDistance && !isCity(value)
			? [i18n.t('therecanonlybea')]
			: [];

		function isCity(location) {
			return location.Type == 1;
		}
	},
};

export default validators;
