import i18n from '../../services/lang';
import moment from 'moment';

const service = {
	formatConfiremdDate(isChecked, dateTimeChangeStatus) {
		if (dateTimeChangeStatus != null) {
			if (isChecked) {
				var date = i18n.t('activated2654') + ' ' + moment(dateTimeChangeStatus).format('DD.MM.YYYY HH:mm');
			} else {
				var date = i18n.t('deactivated') + ' ' + moment(dateTimeChangeStatus).format('DD.MM.YYYY HH:mm');
			}
		} else {
			var date = '';
		}
		return date;
	},

	openChangeActiveModal(modal, changeActiveFnc, url, code, isActive) {
		if (isActive) {
			url += 'Home/DemandDeny?code=' + code;
		} else {
			url += 'Home/DemandConfirm?code=' + code;
		}
		modal.show({
			title: isActive ? i18n.t('deactivatedeman') : i18n.t('activatedemand'),
			text: isActive ? i18n.t('doyouwanttodeac') : i18n.t('doyouwanttoacti'),
			modalClass: 'modal-danger',
			variant: 'danger',
			okTitle: i18n.t('ok2344'),
			cancelTitle: i18n.t('cancel2343'),
			onConfirm() {
				return changeActiveFnc(url);
			},
		});
	},

	changeActiveStatus(url, tableRef) {
		window.open(url, '_blank');
		setTimeout(() => {
			tableRef.refresh();
		}, 4000);
	},
};

export default service;
