var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          tooltipMsg: "manualfordemand3303",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openDeleteModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n\t\t" + _vm._s(_vm.$t("detailofdemand")) + "\n\t\t"
                  ),
                  _c(
                    "b-link",
                    {
                      attrs: {
                        target: "_blank",
                        href:
                          _vm.model.DomainUrl +
                          "Home/DemandPreview?code=" +
                          _vm.model.Code,
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.$t("id")) +
                          " " +
                          _vm._s(_vm.model.OdemandId) +
                          "\n\t\t"
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "customActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("savechangesandc"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.updateAndConfirmDemand()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2315323398
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }