import vueFormGenerator from 'vue-form-generator';
import customValidators from './object-demand.validators';
import serviceEnums from '../../services/service/enums.service';
import service from './object-demand.service';

const checklistOptions = {
	value: 'OspropId',
	name: 'OspropNameSk',
};

const checklistSojournTypesOptions = {
	value: 'OstypeId',
	name: 'OstypeNameSk',
};

const csectionOptions = {
	value: 'CsectionId',
	name: 'CsectionNameSk',
};

export const model = {
	DateRange: {
		startDate: null,
		endDate: null,
	},
};

export const fields = [
	{
		fields: [
			{
				type: 'switchAction',
				model: 'OdemandIsConfirmed',
				titleActivate: 'activatedemand',
				titleDeactivate: 'deactivatedeman',
				textActivate: 'doyouwanttoacti',
				textDeactivate: 'doyouwanttodeac',
				datechange: null,
				onChanged(model) {
					let url =
						this.model.DomainUrl +
						(!this.model.OdemandIsConfirmed ? 'Home/DemandDeny?code=' : 'Home/DemandConfirm?code=') +
						this.model.Code;
					service.changeActiveStatus(url, this.$parent.$parent.$parent);
				},
				i18n: {
					label: 'active:',
					textOn: 'yes2205',
					textOff: 'no2206',
				},
			},
			{
				type: 'label',
				model: 'ObjectsCount',
				i18n: {
					label: 'incount3276',
				},
			},
			{
				type: 'autocomplete',
				model: 'Location',
				typeSearch: '5',
				styleClasses: 'half-width',
				validator: customValidators.locationOnlyCity,
				required: true,
				i18n: {
					label: 'regioncity',
				},
			},
			{
				type: 'select',
				model: 'LocationDistance',
				values: serviceEnums.getEnumForVFG('locationDistance', true, { id: null, name: 'select' }),
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'quarter-width',
				i18n: {
					label: 'distancefromthe',
				},
			},
			{
				type: 'range-datepicker',
				language: 'sk',
				model: 'DateRange',
				i18n: {
					label: 'termofthestay',
				},
				required:true,
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'OdemandQuantityAdults',
				styleClasses: 'eighther-width',
				i18n: {
					label: 'numberofadults',
				},
				required: true,
				validator: vueFormGenerator.validators.integer,
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'OdemandQuantityChildrens',
				styleClasses: 'eighther-width',
				i18n: {
					label: 'numberofchildre',
				},
			},
			{
				type: 'select',
				model: 'OdemandQuantityBedroomsMin',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('dropdownCount'),
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'eighther-width',
				i18n: {
					label: 'numberofbedroom',
				},
			},
			{
				type: 'select',
				model: 'PetRace',
				values: serviceEnums.getEnumForVFG('petRace', true, { id: 0, name: 'select' }),
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'quarter-width',
				i18n: {
					label: 'petsallowed2617',
				},
			},
		],
	},
	{
		styleClasses: 'col-xs-12 inline-input-group',
		fields: [
			{
				type: 'input',
				inputType: 'number',
				model: 'OdemandPriceRangeMin',
				styleClasses: 'col-xs-12 first-input',
				i18n: {
					label: 'priceperpersonn',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'OdemandPriceRangeMax',
				styleClasses: 'col-xs-12 second-input',
				i18n: {
					label: 'to2597',
				},
			},
		],
	},

	{
		fields: [
			{
				type: 'checkboxes-group',
				model: 'OdemandCatalogSections',
				values: [],
				csectionOptions,
				i18n: {
					label: 'propertytype',
				},
				required: true,
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandWithoutOwner',
				values: [],
				i18n: {
					label: 'rentalswithoutt',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandFoodFacilities',
				values: [],
				checklistOptions,
				i18n: {
					label: 'food',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandInternet',
				values: [],
				i18n: {
					label: 'internet2616',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'SingleGroup',
				values: [],
				i18n: {
					label: 'thewholeobjectj3371',
				},
			},
		],
	},

	{
		fields: [
			{
				type: 'checkboxes-group',
				model: 'OdemandRoomsEquipment',
				values: [],
				checklistOptions,
				i18n: {
					label: 'equipmentinbedr',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandSocietyRoomsEquipment',
				values: [],
				checklistOptions,
				i18n: {
					label: 'dayroom',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandWellness',
				values: [],
				checklistOptions,
				i18n: {
					label: 'wellnessandrela',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandNearFacilities',
				values: [],
				checklistOptions,
				i18n: {
					label: 'neartheaccommod',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandChildrenFacilities',
				values: [],
				checklistOptions,
				i18n: {
					label: 'forchildren',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandSportFacilities',
				values: [],
				checklistOptions,
				i18n: {
					label: 'sportsfacilitie',
				},
			},
			{
				type: 'select',
				model: 'Ratings',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('ratings', true, { id: 0, name: 'select' }),
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'quarter-width',
				i18n: {
					label: 'ratings3218',
				},
			},
		],
	},

	{
		fields: [
			{
				type: 'select',
				model: 'OdemandQuantityBathroomsMin',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('dropdownCount'),
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'eighther-width',
				i18n: {
					label: 'numberofbathroo',
				},
			},
			{
				type: 'select',
				model: 'OdemandQuantityKitchensMin',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('dropdownCount'),
				selectOptions: {
					hideNoneSelectedText: true,
				},
				styleClasses: 'eighther-width',
				i18n: {
					label: 'numberofkitchen',
				},
			},
		],
	},

	{
		fields: [
			{
				type: 'checkboxes-group',
				model: 'OdemandLocations',
				values: [],
				checklistOptions,
				i18n: {
					label: 'locationofaccom',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandSuitableForPeople',
				values: [],
				checklistOptions: checklistSojournTypesOptions,
				i18n: {
					label: 'accommodationsu',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandSuitableForActivities',
				values: [],
				checklistOptions: checklistSojournTypesOptions,
				i18n: {
					label: 'accommodationsu2613',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandSocialFacilities',
				values: [],
				checklistOptions,
				i18n: {
					label: 'sharedspaceswit3216',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandCongressFacilities',
				values: [],
				checklistOptions,
				i18n: {
					label: 'forcompanies',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandHavefence',
				values: [],
				i18n: {
					label: 'fencing',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'OdemandBarrierfreeAccess',
				values: [],
				i18n: {
					label: 'barierfree2618',
				},
			},
		],
	},
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'OdemandName',
				i18n: {
					label: 'nameandsurname',
				},
				required: true,
				validator: vueFormGenerator.validators.string,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OdemandEmail',
				i18n: {
					label: "newyear'seve",
				},
				required: true,
				validator: vueFormGenerator.validators.email,

			},
			{
				type: 'input',
				inputType: 'text',
				model: 'OdemandPhone',
				i18n: {
					label: 'phone:',
				},
				required: true,
				validator: vueFormGenerator.validators.string,
			},
			{
				type: 'textArea',
				model: 'OdemandNote',
				min: 1,
				rows: 6,
				required: false,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'remark:',
				},
			},
		],
	},
];
