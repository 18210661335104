<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manualfordemand3303"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofdemand') }}
			<b-link target="_blank" :href="`${model.DomainUrl}Home/DemandPreview?code=${model.Code}`">
				{{ $t('id') }}&nbsp;{{ model.OdemandId }}
			</b-link>
		</template>

		<template #customActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechangesandc')"
				@handleClick="updateAndConfirmDemand()"
			></megau-button>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import http from '@/services/helpers/http';
import enums from '@/services/helpers/enums';
import serviceCommon from '@/services/service/common.service';

import { model, fields } from './object-demand.form-data';
import service from './object-demand.service';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'ObjectDemand',
			routeParam: 'OdemandId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {},

	methods: {
		async loadResources() {
			const [catalogSections, simpleProps, soujournTypes] = await Promise.all([
				http.get('CatalogSection/GetAllVisible').then((res) => res.data),
				http.get('ObjectSimpleProperty/GetForDemand'),
				http.get('ObjectSojournType/GetForDemand'),
			]);

			this.updateSchemaFields({
				OdemandWithoutOwner: { values: [{ value: true, name: this.$i18n.t('rentalswithoutt') }] },
				DateRange: {
					onChanged(model) {
						this.updateFormModel({
							OdemandDateStart: model.startDate,
							OdemandDateEnd: model.endDate,
						});
					},
				},
				OdemandCatalogSections: { values: catalogSections },
				OdemandFoodFacilities: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.Food) },
				OdemandRoomsEquipment: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.RoomsEquipment) },
				OdemandSocietyRoomsEquipment: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.DayroomEquipment) },
				OdemandSocialFacilities: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.PublicAreas) },
				OdemandWellness: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.WellnessAndRelax) },
				OdemandNearFacilities: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.NearObject) },
				OdemandChildrenFacilities: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.Children) },
				OdemandSportFacilities: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.Sport) },
				OdemandCongressFacilities: { values: getSimplePropertyByGroup(enums.simplePropertiesTypes.Congres) },
				OdemandLocations: {
					values: getJoinedSimplePropertyByGroup(
						enums.simplePropertiesTypes.ObjectLocations,
						enums.simplePropertiesTypes.LocationDetails
					),
				},
				OdemandSuitableForPeople: { values: getSojournTypesByGroup(enums.suitableFor.Persons) },
				OdemandSuitableForActivities: { values: getSojournTypesByGroup(enums.suitableFor.Events) },
				OdemandInternet: { values: [{ value: true, name: this.$i18n.t('internet') }] },
				SingleGroup: { values: [{ value: true, name: this.$i18n.t('thewholeobjectj3372') }] },
				OdemandHavefence: { values: [{ value: true, name: this.$i18n.t('fencedland') }] },
				OdemandBarrierfreeAccess: { values: [{ value: true, name: this.$i18n.t('barierfree') }] },
			});

			function getSimplePropertyByGroup(groupId) {
				return simpleProps.data.filter((e) => {
					return e.PtypeId == groupId;
				});
			}

			function getJoinedSimplePropertyByGroup(groupId, joinedGroupId) {
				return simpleProps.data.filter((e) => {
					return e.PtypeId == groupId || e.PtypeId == joinedGroupId;
				});
			}

			function getSojournTypesByGroup(groupId) {
				return soujournTypes.data.filter((e) => {
					return e.CategoryId == groupId;
				});
			}
		},

		transformDtoToModel(dtoModel) {
			dtoModel.DateRange = {
				startDate: dtoModel.OdemandDateStart,
				endDate: dtoModel.OdemandDateEnd,
			};

			this.updateSchemaFields({
				OdemandIsConfirmed: {
					datechange: dtoModel.DemandStatusChangeDatetime
						? service.formatConfiremdDate(this.model.OdemandIsConfirmed, this.model.DemandStatusChangeDatetime)
						: '',
				},
			});

			return dtoModel;
		},

		async updateAndConfirmDemand() {
			await this.saveModel({ reloadAfterSave: false });
			window.open(this.model.DomainUrl + 'Home/DemandConfirm?code=' + this.model.Code);
			setTimeout(this.loadModel, 3000);
			// serviceCommon.insertOrUpdate(this, this.model.OdemandId, this.controllerName, this.confirmAndFetchDemand);
		},
		confirmAndFetchDemand() {},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.OdemandId, this.$modal, this.deleteModel);
		},
	},
};
</script>

<style lang="scss">
.vue-form-generator .inline-input-group {
	.form-group.error {
		position: relative;
		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}
	@include ig-first-item(23%, 79%, 22%);
	@include ig-second-item(10%, 10px);
}
</style>
